import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { collection, addDoc, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, Legend } from 'recharts';
import { Link } from 'react-router-dom';

function PainpointAssessment() {
  const [userType, setUserType] = useState('');
  const [painpoint, setPainpoint] = useState('');
  const [analysis, setAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted');
    setIsLoading(true);
    setError('');
    setAnalysis(null);

    try {
      console.log('Sending request with:', { userType, painpoint });
      const response = await axios.post(
        'https://us-central1-ideahunt-329ef.cloudfunctions.net/painpoint_final',
        {
          userType,
          painpoint
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true
        }
      );
      
      console.log('Received response:', response.data);
      
      let data = response.data;
      
      // If the data is a string, we need to parse it
      if (typeof data === 'string') {
        // Replace NaN with null before parsing
        data = data.replace(/:\s*NaN/g, ': null');
        data = JSON.parse(data);
      }
      
      // If data is already an object (parsed by axios), replace NaN with null
      if (typeof data === 'object') {
        data = JSON.parse(JSON.stringify(data, (key, value) =>
          value !== value ? null : value
        ));
      }

      setAnalysis(data);
    } catch (error) {
      console.error('Error analyzing painpoint:', error);
      if (error.response) {
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      setError('An error occurred while analyzing the painpoint. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveAnalysis = async () => {
    if (!user) {
      console.log('No user logged in, redirecting to login');
      navigate('/login');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      console.log('Attempting to save analysis...');

      // First, ensure the user document exists
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, {
        email: user.email,
        lastUpdated: serverTimestamp()
      }, { merge: true });

      // Now save the analysis with a reference to the user
      const analysisRef = await addDoc(collection(db, 'painpointAnalyses'), {
        userId: user.uid,
        userType,
        painpoint,
        analysis,
        createdAt: serverTimestamp()
      });

      console.log('Analysis saved successfully with ID:', analysisRef.id);
      navigate('/profile');
    } catch (error) {
      console.error('Error saving analysis:', error);
      console.error('Error details:', JSON.stringify(error, null, 2));
      setError(`Failed to save analysis: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderVolume = () => {
    if (!analysis || !analysis.volume_sentiment || !analysis.trends) return null;

    const totalMentions = analysis.volume_sentiment.total_mentions;
    const mentionTrend = analysis.trends.mention_trend;

    let volumeAssessment = "";
    if (totalMentions < 100) volumeAssessment = "This is a relatively low volume of mentions, suggesting the topic might not be widely discussed.";
    else if (totalMentions < 500) volumeAssessment = "This is a moderate volume of mentions, indicating some interest in the topic.";
    else if (totalMentions < 1000) volumeAssessment = "This is a significant volume of mentions, suggesting the topic is quite popular.";
    else volumeAssessment = "This is a high volume of mentions, indicating the topic is widely discussed and very popular.";

    const currentVolumeData = [{ name: 'Total Mentions', value: totalMentions }];
    const trendData = Object.entries(mentionTrend.data).map(([date, value]) => ({ date, value }));

    let trendAssessment = "";
    if (mentionTrend.direction.trend === "Increasing") {
      trendAssessment = "The volume of mentions is increasing over time, suggesting growing interest or relevance of the topic.";
    } else if (mentionTrend.direction.trend === "Decreasing") {
      trendAssessment = "The volume of mentions is decreasing over time, which might indicate declining interest or relevance of the topic.";
    } else {
      trendAssessment = "The volume of mentions is relatively stable over time, suggesting consistent interest in the topic.";
    }

    return (
      <div className="mb-12">
        <h3 className="text-2xl font-semibold text-white mb-6">Volume Analysis</h3>
        
        <div className="bg-gray-700 p-4 rounded-lg mb-6">
          <h4 className="text-xl font-semibold text-white mb-4">Current Volume</h4>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={currentVolumeData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#3B82F6" />
            </BarChart>
          </ResponsiveContainer>
          <p className="text-white mt-4">Total relevant posts/comments: {totalMentions}</p>
          <p className="text-white mt-2">{volumeAssessment}</p>
        </div>

        <div className="bg-gray-700 p-4 rounded-lg">
          <h4 className="text-xl font-semibold text-white mb-4">Volume Trend</h4>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={trendData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#3B82F6" />
            </LineChart>
          </ResponsiveContainer>
          <p className="text-white mt-4">Trend: {mentionTrend.direction.trend}</p>
          <p className="text-white mt-2">{trendAssessment}</p>
        </div>
      </div>
    );
  };

  const renderSentiment = () => {
    if (!analysis || !analysis.volume_sentiment || !analysis.trends) return null;

    const sentimentData = [
      {
        name: 'Sentiment',
        Positive: analysis.volume_sentiment.positive_percentage,
        Neutral: analysis.volume_sentiment.neutral_percentage,
        Negative: analysis.volume_sentiment.negative_percentage,
      }
    ];

    const colors = {
      Positive: '#4CAF50',
      Neutral: '#FFA726',
      Negative: '#EF5350'
    };

    const sentimentTrend = analysis.trends.sentiment_trend;
    const trendData = Object.entries(sentimentTrend.data).map(([date, value]) => ({ date, value }));

    let currentSentimentAssessment = "";
    if (sentimentData[0].Positive > sentimentData[0].Negative) {
      currentSentimentAssessment = "The overall sentiment is predominantly positive, which suggests a favorable perception of the topic.";
    } else if (sentimentData[0].Positive < sentimentData[0].Negative) {
      currentSentimentAssessment = "The overall sentiment is predominantly negative, which might indicate concerns or issues with the topic.";
    } else {
      currentSentimentAssessment = "The sentiment is balanced between positive and negative, suggesting mixed opinions on the topic.";
    }

    let trendAssessment = "";
    if (sentimentTrend.direction.trend === "Increasing") {
      trendAssessment = "The sentiment is becoming more positive over time, which could indicate improving perceptions or experiences related to the topic.";
    } else if (sentimentTrend.direction.trend === "Decreasing") {
      trendAssessment = "The sentiment is becoming more negative over time, which might suggest growing concerns or dissatisfaction with the topic.";
    } else {
      trendAssessment = "The sentiment remains relatively stable over time, indicating consistent perceptions of the topic.";
    }

    return (
      <div className="mb-12">
        <h3 className="text-2xl font-semibold text-white mb-6">Sentiment Analysis</h3>
        
        <div className="bg-gray-700 p-4 rounded-lg mb-6">
          <h4 className="text-xl font-semibold text-white mb-4">Current Sentiment</h4>
          <ResponsiveContainer width="100%" height={100}>
            <BarChart data={sentimentData} layout="vertical" stackOffset="expand">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" tickFormatter={(value) => `${(value * 100).toFixed(0)}%`} />
              <YAxis type="category" dataKey="name" hide />
              <Tooltip formatter={(value) => `${(value * 100).toFixed(2)}%`} labelFormatter={() => ''} />
              <Legend />
              {Object.keys(colors).map((key) => (
                <Bar key={key} dataKey={key} stackId="a" fill={colors[key]} />
              ))}
            </BarChart>
          </ResponsiveContainer>
          <div className="mt-4 flex justify-between text-sm">
            {Object.entries(sentimentData[0]).filter(([key]) => key !== 'name').map(([key, value]) => (
              <div key={key} className="flex items-center">
                <div className={`w-3 h-3 rounded-full mr-2`} style={{backgroundColor: colors[key]}}></div>
                <span className="text-white">{key}: {(value * 100).toFixed(2)}%</span>
              </div>
            ))}
          </div>
          <p className="text-white mt-4">{currentSentimentAssessment}</p>
        </div>

        <div className="bg-gray-700 p-4 rounded-lg">
          <h4 className="text-xl font-semibold text-white mb-4">Sentiment Trend</h4>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={trendData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#4CAF50" />
            </LineChart>
          </ResponsiveContainer>
          <p className="text-white mt-4">Trend: {sentimentTrend.direction.trend}</p>
          <p className="text-white mt-2">{trendAssessment}</p>
        </div>
      </div>
    );
  };

  const renderSubredditAnalysis = () => {
    if (!analysis || !analysis.subreddit_analysis) return null;

    const { most_relevant_posts, highest_engagement } = analysis.subreddit_analysis;

    const relevantPostsData = Object.entries(most_relevant_posts).map(([name, value]) => ({ name, value }));
    const engagementData = Object.entries(highest_engagement).map(([name, value]) => ({ name, value: parseFloat(value.toFixed(2)) }));

    const topSubreddit = relevantPostsData.reduce((a, b) => a.value > b.value ? a : b);
    const highestEngagementSubreddit = engagementData.reduce((a, b) => a.value > b.value ? a : b);

    return (
      <div className="mb-12">
        <h3 className="text-2xl font-semibold text-white mb-6">Subreddit Analysis</h3>
        
        <div className="bg-gray-700 p-4 rounded-lg mb-6">
          <h4 className="text-xl font-semibold text-white mb-4">Most Relevant Posts by Subreddit</h4>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={relevantPostsData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
          <p className="text-white mt-4">
            The subreddit with the most relevant posts is r/{topSubreddit.name} with {topSubreddit.value} posts.
            This suggests that r/{topSubreddit.name} is a key community for discussions related to your topic.
          </p>
        </div>

        <div className="bg-gray-700 p-4 rounded-lg">
          <h4 className="text-xl font-semibold text-white mb-4">Highest Engagement by Subreddit</h4>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={engagementData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
          <p className="text-white mt-4">
            The subreddit with the highest engagement is r/{highestEngagementSubreddit.name} with an average of {highestEngagementSubreddit.value} comments per post.
            This indicates that discussions in r/{highestEngagementSubreddit.name} tend to generate more conversation and interaction.
          </p>
        </div>

        <p className="text-white mt-6">
          Consider focusing your efforts on r/{topSubreddit.name} for maximum reach and r/{highestEngagementSubreddit.name} for deeper engagement with your audience.
          These subreddits appear to be the most relevant and active communities for your topic.
        </p>
      </div>
    );
  };

  const renderAnalysisResults = () => {
    if (!analysis) return <p className="text-white"></p>;

    return (
      <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg">
        <h2 className="text-2xl font-bold text-white mb-8">Analysis Results</h2>
        {renderVolume()}
        {renderSentiment()}
        {renderSubredditAnalysis()}
      </div>
    );
  };

  return (
    <div className="min-h-screen w-full bg-gradient-to-r from-gray-900 via-gray-800 to-black flex flex-col">
      <div className="container mx-auto max-w-4xl flex-grow">
        <h1 className="text-3xl font-bold text-white mb-24 text-center mt-8">Painpoint Validator</h1>
        
        <div className="flex justify-center mb-8">
          {analysis && (
            <button
              onClick={handleSaveAnalysis}
              className="bg-gradient-to-r from-cyan-400 to-blue-500 text-white font-bold py-2 px-4 rounded"
              disabled={isLoading}
            >
              {isLoading ? 'Saving...' : 'Save Analysis'}
            </button>
          )}
        </div>
        <form onSubmit={handleSubmit} className="mb-8">
          <div className="mb-4">
            <label htmlFor="userType" className="block text-white mb-2">Who is your user?</label>
            <input
              type="text"
              id="userType"
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
              className="w-full p-2 rounded-md bg-gray-800 text-white placeholder-gray-500 border-none outline-none"
              required
              placeholder="e.g., Small business owners, College students, etc."
            />
          </div>
          <div className="mb-4">
            <label htmlFor="painpoint" className="block text-white mb-2">What painpoint are you solving?</label>
            <textarea
              id="painpoint"
              value={painpoint}
              onChange={(e) => setPainpoint(e.target.value)}
              className="w-full p-2 rounded-md h-32 bg-gray-800 text-white placeholder-gray-500 border-none outline-none"
              required
              placeholder="Describe the problem you're addressing..."
            />
          </div>
          <button
            type="submit"
            className="bg-gray-700 text-white border-2 border-gray-600 px-4 py-2 rounded-md hover:bg-gray-600 transition-colors w-full"
            disabled={isLoading}
          >
            Analyze Painpoint
          </button>
          {isLoading && (
            <div className="flex flex-col items-center mt-4">
              <div className="w-6 h-6 border-4 border-t-cyan-400 border-r-blue-500 border-b-purple-400 border-l-pink-500 rounded-full animate-spin"></div>
              <div className="text-cyan-400 mt-2">Analyzing Painpoint</div>
            </div>
          )}
        </form>

        {error && (
          <div className="bg-red-500 bg-opacity-50 text-white p-4 rounded-md mb-4">
            {error}
          </div>
        )}

        {!isLoading && renderAnalysisResults()}
      </div>
      <Footer />
    </div>
  );
}

function Footer() {
  return (
    <footer className="bg-gray-800 py-4 sm:py-6">
      <div className="max-w-4xl mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
        <p className="text-gray-400 text-xs sm:text-sm mb-2 sm:mb-0">© 2024 - Painpoint Validator by HomeOfFounders</p>
        <div className="flex space-x-4">
          <Link to="/blogs" className="text-gray-400 hover:text-white text-xs sm:text-sm">
            Blog
          </Link>
          <Link to="/contact" className="text-gray-400 hover:text-white text-xs sm:text-sm">
            Contact Us
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default PainpointAssessment;
