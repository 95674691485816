import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, doc, getDoc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { Book, Podcast, User, ArrowRight, ChevronDown, ChevronUp, Edit3 } from 'lucide-react';

function Profile() {
  const [analyses, setAnalyses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  // New state for username and profile picture
  const [username, setUsername] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profilePictureFile, setProfilePictureFile] = useState(null);

  const storage = getStorage();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUsername(userData.username || user.email);
            setProfilePicture(userData.profilePicture || null);
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      }
    };

    fetchUserProfile();
  }, [user]);

  // Function to handle username update
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const saveProfile = async () => {
    try {
      let profilePictureURL = profilePicture;

      // If a new profile picture is selected, upload it
      if (profilePictureFile) {
        const storageRef = ref(storage, `profilePictures/${user.uid}`);
        await uploadBytes(storageRef, profilePictureFile);
        profilePictureURL = await getDownloadURL(storageRef);
      }

      const userDocRef = doc(db, 'users', user.uid);
      await setDoc(userDocRef, { username, profilePicture: profilePictureURL }, { merge: true });
      console.log('Profile updated successfully');
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  // Function to handle profile picture upload
  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePictureFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // State for expandable sections
  const [expandedSections, setExpandedSections] = useState({
    painpointAssessments: false, // Set to false to keep it folded by default
    painpointDiscovery: false,
    solutionDiscovery: false,
    books: false,
    podcasts: false,
    people: false,
  });

  const [userMedia, setUserMedia] = useState({
    books: [],
    podcasts: [],
    people: []
  });

  const [filterStatus, setFilterStatus] = useState({
    books: 'all',
    podcasts: 'all',
    people: 'all'
  });

  const handleFilterChange = (mediaType, status) => {
    setFilterStatus(prev => ({
      ...prev,
      [mediaType]: status
    }));
  };

  useEffect(() => {
    const fetchAnalyses = async () => {
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        const q = query(collection(db, 'painpointAnalyses'), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const userAnalyses = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setAnalyses(userAnalyses);
      } catch (err) {
        console.error('Error fetching analyses:', err);
        setError('Failed to fetch analyses. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchAnalyses();
  }, [user]);

  useEffect(() => {
    const fetchUserMedia = async () => {
      if (user) {
        try {
          console.log('Fetching media for user:', user.uid);
          const q = collection(db, 'users', user.uid, 'savedResources');
          const querySnapshot = await getDocs(q);
          const media = {
            books: [],
            podcasts: [],
            people: []
          };
          querySnapshot.forEach((doc) => {
            const item = doc.data();
            console.log('Fetched media item:', item); // Debugging line
            if (item.type === 'media' && media[item.category]) {
              media[item.category].push(item);
            } else {
              console.warn(`Unknown media category: ${item.category}`);
            }
          });
          console.log('Fetched user media:', media); // Debugging line
          setUserMedia(media);
        } catch (error) {
          console.error("Error fetching user media:", error);
        }
      }
    };
    fetchUserMedia();
  }, [user]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Failed to log out', error);
      setError('Failed to log out. Please try again.');
    }
  };

  const handleAnalysisClick = (analysisId) => {
    navigate(`/saved-analysis/${analysisId}`);
  };

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const handleStatusChange = async (item) => {
    try {
      const newStatus = item.status === 'following' ? 'to follow' : 'following';
      const docRef = doc(db, 'users', user.uid, 'savedResources', item.id);
      await setDoc(docRef, { ...item, status: newStatus }, { merge: true });
      setUserMedia(prevMedia => {
        const updatedMedia = { ...prevMedia };
        const category = item.category;
        updatedMedia[category] = updatedMedia[category].map(mediaItem =>
          mediaItem.id === item.id ? { ...mediaItem, status: newStatus } : mediaItem
        );
        return updatedMedia;
      });
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleRemoveItem = async (item) => {
    try {
      const docRef = doc(db, 'users', user.uid, 'savedResources', item.id);
      await setDoc(docRef, { ...item, status: 'removed' }, { merge: true });
      setUserMedia(prevMedia => {
        const updatedMedia = { ...prevMedia };
        const category = item.category;
        updatedMedia[category] = updatedMedia[category].filter(mediaItem => mediaItem.id !== item.id);
        return updatedMedia;
      });
    } catch (error) {
      console.error("Error removing item:", error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const ExpandableSection = ({ title, content, isExpanded, onToggle, icon }) => (
    <div className="bg-gray-800 p-6 rounded-lg mb-6">
      <div 
        className="flex justify-between items-center cursor-pointer"
        onClick={onToggle}
      >
        <h3 className="text-xl font-bold text-white flex items-center">
          {icon && React.createElement(icon, { className: "mr-2" })}
          {title}
        </h3>
        {isExpanded ? <ChevronUp /> : <ChevronDown />}
      </div>
      {isExpanded && <div className="mt-4">{content}</div>}
    </div>
  );

  return (
    <div className="min-h-screen w-full bg-gradient-to-r from-gray-900 via-gray-800 to-black p-4">
      <div className="container mx-auto max-w-4xl">
        <div className="flex justify-between items-center mb-8">
        </div>

        {/* User Profile Section */}
        <div className="bg-gray-800 p-6 rounded-lg mb-6">
          <div className="flex items-center mb-4">
            <div className="mr-4">
              {profilePicture ? (
                <img src={profilePicture} alt="Profile" className="w-16 h-16 rounded-full" />
              ) : (
                <div className="w-16 h-16 rounded-full bg-gray-600 flex items-center justify-center text-white">
                  <User />
                </div>
              )}
            </div>
            <div className="flex-1">
              <h2 className="text-2xl font-bold text-white">{username}</h2>
            </div>
            <div className="flex flex-col items-center">
              <button
                onClick={() => setIsModalOpen(true)}
                className="text-white hover:text-gray-300"
              >
                <Edit3 />
              </button>
              <span className="text-sm text-gray-400">Edit Profile</span>
            </div>
          </div>
        </div>

        {/* Profile Edit Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-gray-800 p-6 rounded-lg w-96">
              <h2 className="text-2xl font-bold text-white mb-4">Edit Profile</h2>
              <div className="mb-4">
                <label className="block text-white mb-2">Edit Username</label>
                <input
                  type="text"
                  value={username}
                  onChange={handleUsernameChange}
                  className="bg-gray-700 text-white p-2 rounded w-full"
                  placeholder="Enter your username"
                />
              </div>
              <div className="mb-4">
                <label className="block text-white mb-2">Edit Profile Picture</label>
                <input type="file" accept="image/*" onChange={handleProfilePictureChange} className="text-white" />
              </div>
              <div className="flex justify-end">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={saveProfile}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="mb-12">
          <h2 className="text-2xl font-bold text-white mb-4">App Usage</h2>
          
          <ExpandableSection
            title="Painpoint Assessments"
            isExpanded={expandedSections.painpointAssessments}
            onToggle={() => toggleSection('painpointAssessments')}
            content={
              analyses.length === 0 ? (
                <p className="text-white">No analyses found. Start by creating a new analysis!</p>
              ) : (
                analyses.map(analysis => (
                  <div 
                    key={analysis.id} 
                    className="bg-gray-700 p-4 rounded-lg mb-4 cursor-pointer hover:bg-gray-600 transition-colors"
                    onClick={() => handleAnalysisClick(analysis.id)}
                  >
                    <h4 className="text-lg font-bold text-white mb-2">{analysis.userType}</h4>
                    <p className="text-gray-300 mb-2">{analysis.painpoint}</p>
                    <p className="text-gray-400 text-sm">
                      Created: {analysis.createdAt.toDate().toLocaleString()}
                    </p>
                  </div>
                ))
              )
            }
          />

          <ExpandableSection
            title="Painpoint Discovery"
            isExpanded={expandedSections.painpointDiscovery}
            onToggle={() => toggleSection('painpointDiscovery')}
            content={<p className="text-white">No discoveries yet. Start exploring painpoints!</p>}
          />

          <ExpandableSection
            title="Solution Discovery"
            isExpanded={expandedSections.solutionDiscovery}
            onToggle={() => toggleSection('solutionDiscovery')}
            content={<p className="text-white">No solutions proposed yet. Start brainstorming!</p>}
          />
        </div>

        <div>
          <h2 className="text-2xl font-bold text-white mb-4">My Media</h2>
          {['Books', 'Podcasts', 'People'].map((mediaType, index) => (
            <ExpandableSection
              key={index}
              title={mediaType}
              isExpanded={expandedSections[mediaType.toLowerCase()]}
              onToggle={() => toggleSection(mediaType.toLowerCase())}
              icon={mediaType === 'Books' ? Book : mediaType === 'Podcasts' ? Podcast : User}
              content={
                <>
                  <div className="mb-4 flex justify-start space-x-2">
                    <button
                      onClick={() => handleFilterChange(mediaType.toLowerCase(), 'all')}
                      className={`px-2 py-1 text-sm rounded-full border ${filterStatus[mediaType.toLowerCase()] === 'all' ? 'bg-blue-500 text-white' : 'bg-gray-800 text-gray-300'} hover:bg-blue-400 hover:text-white transition-colors`}
                    >
                      All
                    </button>
                    <button
                      onClick={() => handleFilterChange(mediaType.toLowerCase(), mediaType === 'Books' ? 'read' : 'following')}
                      className={`px-2 py-1 text-sm rounded-full border ${filterStatus[mediaType.toLowerCase()] === (mediaType === 'Books' ? 'read' : 'following') ? 'bg-blue-500 text-white' : 'bg-gray-800 text-gray-300'} hover:bg-blue-400 hover:text-white transition-colors`}
                    >
                      {mediaType === 'Books' ? 'Read' : 'Following'}
                    </button>
                    <button
                      onClick={() => handleFilterChange(mediaType.toLowerCase(), mediaType === 'Books' ? 'to read' : 'to follow')}
                      className={`px-2 py-1 text-sm rounded-full border ${filterStatus[mediaType.toLowerCase()] === (mediaType === 'Books' ? 'to read' : 'to follow') ? 'bg-blue-500 text-white' : 'bg-gray-800 text-gray-300'} hover:bg-blue-400 hover:text-white transition-colors`}
                    >
                      {mediaType === 'Books' ? 'To Read' : 'To Follow'}
                    </button>
                  </div>
                  {userMedia[mediaType.toLowerCase()]
                    .filter(item => filterStatus[mediaType.toLowerCase()] === 'all' || item.status === filterStatus[mediaType.toLowerCase()])
                    .map((item, i) => (
                      <div key={i} className="bg-gray-700 p-4 rounded-lg mb-4 relative">
                        <h4 className="text-lg font-bold text-white mb-2">{item.title}</h4>
                        <p className="text-gray-300 mb-2">{item.description}</p>
                        <a href={item.link} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                          Learn More
                        </a>
                        <button
                          onClick={() => handleRemoveItem(item)}
                          className="absolute top-2 right-2 text-white font-bold py-1 px-2 text-sm rounded bg-red-600 hover:bg-red-700"
                        >
                          ✗
                        </button>
                        <button
                          onClick={() => handleStatusChange(item)}
                          className={`absolute bottom-2 right-2 text-white font-bold py-1 px-2 text-sm rounded ${item.status === (mediaType === 'Books' ? 'read' : 'following') ? 'bg-green-500' : 'bg-red-500'}`}
                        >
                          {item.status === (mediaType === 'Books' ? 'read' : 'following') ? (mediaType === 'Books' ? 'Read ✓' : 'Following ✓') : (mediaType === 'Books' ? 'Not Read' : 'Follow')}
                        </button>
                      </div>
                    ))}
                  <Link 
                    to={`/resources/media#${mediaType.toLowerCase()}`} 
                    className="text-blue-400 hover:text-blue-300 flex items-center"
                  >
                    Add {mediaType}
                    <ArrowRight className="ml-2 w-4 h-4" />
                  </Link>
                </>
              }
            />
          ))}
        </div>

        {/* Logout Button at the Bottom */}
        <div className="flex justify-center mt-8">
          <button
            onClick={handleLogout}
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

export default Profile;
