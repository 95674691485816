import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Ensure this path is correct
import { Book, Podcast, User, ArrowRight, Wrench, Film } from 'lucide-react'; // Import icons

function Footer() {
  return (
    <footer className="bg-gray-800 py-4 sm:py-6">
      <div className="max-w-4xl mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
        <p className="text-gray-400 text-xs sm:text-sm mb-2 sm:mb-0">© 2024 - <span className="bg-gradient-to-r from-cyan-400 to-blue-500 bg-clip-text text-transparent">Home</span>of<span className="bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">Founders</span></p>
        <div className="flex space-x-4">
          <Link to="/blogs" className="text-gray-400 hover:text-white text-xs sm:text-sm">
            Blog
          </Link>
          <Link to="/contact" className="text-gray-400 hover:text-white text-xs sm:text-sm">
            Contact Us
          </Link>
        </div>
      </div>
    </footer>
  );
}

const IdeaValidationLanding = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleTryForFree = () => {
    navigate('/apps');
  };

  const apps = [
    {
      title: "Painpoint Assessment",
      description: "Are you looking to solve a real problem for your customer?",
      link: "/painpoint-validator-landing"
    },
    {
      title: "Understand the Painpoint",
      description: "What are the underlying causes of the problem?",
      link: "/painpoint-analysis",
      comingSoon: true
    },
    {
      title: "Solution Evaluation",
      description: "What are the best ways to solve the problem?",
      link: "/solution-evaluation",
      comingSoon: true
    }
  ];

  const resources = [
    { title: "Tools", icon: Wrench, link: "/resources/tools" },
    { title: "Media", icon: Film, link: "/resources/media" },
  ];

  return (
    <div className="min-h-screen w-full bg-gradient-to-r from-gray-900 via-gray-800 to-black">
      <div className="max-w-6xl mx-auto px-4 py-16">
        <h1 className="text-5xl md:text-6xl font-bold text-white mb-10 font-sans tracking-tight leading-tight text-center">
          <span className="bg-gradient-to-r from-cyan-400 to-blue-500 bg-clip-text text-transparent">Home</span>of<span className="bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">Founders</span>
        </h1>
        <h2 className="text-1xl md:text-3xl font-bold text-white text-center mb-16">
        Apps to build your startup. <br />
        Resources to grow as a founder.
        </h2>
        
        <div className="flex flex-col md:flex-row justify-between gap-8 mb-24">
          <ContentBubble title="Build with intention" className="md:w-1/3 text-center">
            Each step of the journey has a purpose.
          </ContentBubble>
          <ContentBubble title="Learn and improve"  className="md:w-1/3 text-center">
            Your startup will only be good as you are.
          </ContentBubble>
          <ContentBubble title="Centralize your journey" className="md:w-1/3 text-center">
            Keep it all in one place.
          </ContentBubble>
        </div>
        <div className="flex flex-col items-center space-y-4 mb-16">
          {user ? (
            <>
              <div className="flex space-x-4">
                <Link 
                  to="/apps"
                  className="bg-gray-700 text-white px-8 py-3 rounded-full hover:bg-gray-600 transition-colors text-lg font-semibold"
                >
                  Apps
                </Link>
                <Link 
                  to="/profile"
                  className="bg-gray-700 text-white px-8 py-3 rounded-full hover:bg-gray-600 transition-colors text-lg font-semibold"
                >
                  Profile
                </Link>
              </div>
            </>
          ) : (
            <>
              <div className="flex space-x-8">
              </div>
              <Link 
                to="/login"
                className="bg-gradient-to-r from-cyan-400 via-blue-500 to-pink-500 text-white px-8 py-3 rounded-full hover:opacity-90 transition-colors text-lg font-semibold"
              >
                Sign up for free
              </Link>
            </>
          )}
        </div>

        <hr className="border-gray-700 mb-16" />

        <h2 className="text-3xl font-bold text-white mb-8 text-center">Our Apps</h2>
        <div className="space-y-8 mb-16">
          {apps.map((app, index) => (
            <AppCard key={index} {...app} />
          ))}
        </div>

        <hr className="border-gray-700 my-16" />

        {/* "Grow as a founder" section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-white mb-8 text-center">Grow as a founder</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-gray-800 rounded-lg p-6">
              <h3 className="text-xl font-bold text-white mb-4">Resources</h3>
              <div className="space-y-4">
                {resources.map((resource, index) => (
                  <Link 
                    key={index} 
                    to={resource.link}
                    className="flex items-center text-gray-300 hover:text-white transition-colors border-4 border-gradient-to-r from-cyan-400 via-blue-500 to-pink-500 p-4 rounded-lg"
                    style={{
                      borderImage: 'linear-gradient(to right, #22d3ee, #3b82f6, #ec4899) 1'
                    }}
                  >
                    <resource.icon className="w-5 h-5 mr-2" />
                    <span>{resource.title}</span>
                    <ArrowRight className="w-4 h-4 ml-auto" />
                  </Link>
                ))}
              </div>
            </div>
            <div className="bg-gray-800 rounded-lg p-6">
              <h3 className="text-xl font-bold text-white mb-4">Your Profile</h3>
              <p className="text-gray-300 mb-4">
                {user
                  ? "Track your progress, see your learnings, and manage your startup journey."
                  : "Create an account to track your progress, save your work, and access exclusive resources."}
              </p>
              <Link 
                to={user ? "/profile" : "/login"}
                className="bg-gray-700 text-white px-4 py-2 rounded-full hover:bg-gray-600 transition-colors inline-flex items-center"
              >
                Profile
                <ArrowRight className="w-4 h-4 ml-2" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const ContentBubble = ({ title, children, isHighlighted = false, className = "" }) => {
  const baseClasses = "rounded-xl p-6 text-white";
  const bgClasses = isHighlighted
    ? "bg-gray-800 border border-gray-700"
    : "bg-gray-900";

  return (
    <div className={`${baseClasses} ${bgClasses} ${className}`}>
      <h2 className="text-xl font-semibold mb-2">{title}</h2>
      <p className="text-sm text-gray-300">{children}</p>
    </div>
  );
};

const AppCard = ({ title, description, link, comingSoon = false }) => (
  <div 
    className={`bg-gray-800 rounded-lg p-6 ${comingSoon ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700'} transition-colors border-4 border-gradient-to-r from-cyan-400 via-blue-500 to-pink-500`}
    style={{
      borderImage: 'linear-gradient(to right, #22d3ee, #3b82f6, #ec4899) 1'
    }}
  >
    <div className="flex justify-between items-start mb-2">
      <h3 className="text-xl font-bold text-white">{title}</h3>
      {comingSoon && (
        <span className="bg-gray-700 text-xs text-gray-300 px-2 py-1 rounded-full">
          Coming Soon
        </span>
      )}
    </div>
    <p className="text-gray-300 mb-4">{description}</p>
    {comingSoon ? (
      <button className="bg-gray-700 text-white px-4 py-2 rounded-full opacity-50 cursor-not-allowed" disabled>
        Try Now
      </button>
    ) : (
      <Link to={link} className="bg-gray-700 text-white px-4 py-2 rounded-full hover:bg-gray-600 transition-colors inline-block">
        Try Now
      </Link>
    )}
  </div>
);

export default IdeaValidationLanding;
