import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase';
import { doc, setDoc, deleteDoc, getDoc } from 'firebase/firestore';

const AddableResourceCard = ({ resource, type }) => {
  const { user } = useAuth();
  const [isAdded, setIsAdded] = useState(false);

  useEffect(() => {
    const checkIfAdded = async () => {
      if (user && user.uid) {
        const docRef = doc(db, 'users', user.uid, 'savedResources', resource.id);
        const docSnap = await getDoc(docRef);
        setIsAdded(docSnap.exists());
      }
    };
    checkIfAdded();
  }, [user, resource.id]);

  const handleAddToProfile = async () => {
    if (!user) {
      console.log("User must be logged in to add to profile");
      return;
    }

    try {
      const docRef = doc(db, 'users', user.uid, 'savedResources', resource.id);
      if (isAdded) {
        await deleteDoc(docRef);
        setIsAdded(false);
        console.log(`Resource ${resource.id} removed from profile`);
      } else {
        const initialStatus = type === 'books' ? 'to read' : type === 'podcasts' ? 'to listen' : 'to follow';
        await setDoc(docRef, {
          ...resource,
          type,
          status: initialStatus,
          addedAt: new Date()
        });
        setIsAdded(true);
        console.log(`Resource ${resource.id} added to profile with status: ${initialStatus}`);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <div className="bg-gray-800 rounded-lg p-6 flex flex-col h-full">
      <h3 className="text-xl font-bold text-white mb-2">{resource.name || resource.title}</h3>
      <p className="text-gray-300 mb-4 flex-grow">{resource.description}</p>
      <div className="flex justify-between items-center">
        <a 
          href={resource.link || resource.url} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="text-blue-400 hover:text-blue-300"
        >
          Learn More
        </a>
        {user && (
          <button
            onClick={handleAddToProfile}
            className={`px-3 py-1 rounded ${
              isAdded
                ? 'bg-green-600 text-white hover:bg-green-700'
                : 'bg-blue-600 text-white hover:bg-blue-700'
            }`}
          >
            {isAdded ? 'Remove' : 'Add to Profile'}
          </button>
        )}
      </div>
    </div>
  );
};

export default AddableResourceCard;
