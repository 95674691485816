import React, { useState } from 'react';

const SuggestToolButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await fetch('https://us-central1-ideahunt-329ef.cloudfunctions.net/suggestTool', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url, description, email }),
      });

      if (response.ok) {
        setSubmitMessage('Thank you for your suggestion! We will review it soon.');
        setUrl('');
        setDescription('');
        setTimeout(() => setIsOpen(false), 3000);
      } else {
        setSubmitMessage('An error occurred. Please try again.');
      }
    } catch (error) {
      setSubmitMessage('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const inputClass = "mt-1 block w-full rounded-md bg-gray-800 border-gray-700 text-white focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 text-base py-2 px-3";

  return (
    <>
      <button
        className="bg-gradient-to-r from-blue-600 to-blue-800 text-white font-bold py-1 px-3 sm:py-2 sm:px-4 rounded-full hover:from-blue-700 hover:to-blue-900 transition-all duration-200 text-xs sm:text-sm"
        disabled={isSubmitting}
        onClick={() => setIsOpen(true)}
      >
        Suggest a tool
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-900 p-6 rounded-lg w-full max-w-md">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-100">Suggest a Tool</h2>
              <button
                className="text-gray-300 hover:text-gray-100 text-xl"
                onClick={() => setIsOpen(false)}
              >
                ✕
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
                  Email Address
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className={inputClass}
                />
              </div>
              <div>
                <label htmlFor="url" className="block text-sm font-medium text-gray-300 mb-1">
                  Tool URL
                </label>
                <input
                  id="url"
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  required
                  className={inputClass}
                />
              </div>
              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-300 mb-1">
                  Description
                </label>
                <textarea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                  className={inputClass}
                  rows="3"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-blue-600 to-blue-800 text-white font-bold py-3 px-4 rounded hover:from-blue-700 hover:to-blue-900 transition-all duration-200 text-base"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
              {submitMessage && (
                <p className="text-center text-sm mt-2 text-gray-300">{submitMessage}</p>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default SuggestToolButton;
