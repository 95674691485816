import React, { useState } from 'react';
import { FaTrash } from 'react-icons/fa';

function ToDoPage() {
  const [tasks, setTasks] = useState([]);
  const [task, setTask] = useState('');

  const addTask = () => {
    if (task.trim()) {
      setTasks([...tasks, { text: task, completed: false }]);
      setTask('');
    }
  };

  const toggleTaskCompletion = (index) => {
    const newTasks = tasks.map((task, i) =>
      i === index ? { ...task, completed: !task.completed } : task
    );
    setTasks(newTasks);
  };

  const deleteTask = (index) => {
    const newTasks = tasks.filter((_, i) => i !== index);
    setTasks(newTasks);
  };

  return (
    <div className="bg-gray-800 rounded-lg p-6 mb-6 transition-colors border-4 border-gradient-to-r from-cyan-400 via-blue-500 to-pink-500 max-w-md mx-auto mt-16">
      <h3 className="text-xl font-bold text-white mb-4">To-Do List</h3>
      <div className="flex justify-between items-center mb-4">
        <input
          type="text"
          value={task}
          onChange={(e) => setTask(e.target.value)}
          className="flex-grow p-2 border rounded bg-gray-700 text-white"
          placeholder="Add a new task"
        />
        <button
          onClick={addTask}
          className="ml-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Add
        </button>
      </div>
      <ul className="list-disc pl-5">
        {tasks.map((task, index) => (
          <li key={index} className="flex justify-between items-center mb-2">
            <FaTrash
              onClick={() => deleteTask(index)}
              className="text-black cursor-pointer mr-2 hover:text-gray-500"
              size={14}
            />
            <span
              className={`flex-grow cursor-pointer ${
                task.completed ? 'line-through text-gray-500' : 'text-white'
              }`}
            >
              {task.text}
            </span>
            <input
              type="checkbox"
              checked={task.completed}
              onChange={() => toggleTaskCompletion(index)}
              className="ml-2"
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ToDoPage;