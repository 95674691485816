import React from 'react';
import { Link } from 'react-router-dom';



function Footer() {
  return (
    <footer className="bg-gray-800 py-4 sm:py-6">
      <div className="max-w-4xl mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
        <p className="text-gray-400 text-xs sm:text-sm mb-2 sm:mb-0">© 2024 - HomeOfFounders</p>
        <div className="flex space-x-4">
          <Link to="/blogs" className="text-gray-400 hover:text-white text-xs sm:text-sm">
            Blog
          </Link>
          <Link to="/contact" className="text-gray-400 hover:text-white text-xs sm:text-sm">
            Contact Us
          </Link>
        </div>
      </div>
    </footer>
  );
}

const blogPosts = [
  {
    id: 'future-of-startups',
    title: 'The Future of Startups: Verticalised Agents as First Hires',
    excerpt: 'Speculating on the impact of A.I systems, specifically verticalised agents, as tech companies first hires.',
    borderStyle: {
      borderImage: 'linear-gradient(to right, #22d3ee, #3b82f6, #ec4899) 1',
      border: '4px solid',
      borderRadius: '8px'
    }
  },
  {
    id: 'validating-an-idea', 
    title: 'Early Stage Pain-point: Validating an Idea',
    excerpt: 'Exploring the theory and practical steps behind validating a startup idea before building.',
    borderStyle: {
      borderImage: 'linear-gradient(to right, #22d3ee, #3b82f6, #ec4899) 1',
      border: '4px solid',
      borderRadius: '8px'
    }
  },
  // Add more blog posts here as needed
];


const BlogList = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-900 via-gray-700 to-gray-800">
      <div className="flex-grow p-4 sm:p-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl sm:text-4xl font-bold mb-8 text-center text-gray-100">Blog Articles</h1>
          <div className="space-y-6">
            {blogPosts.map((post) => (
              <div key={post.id} className="bg-gray-800 bg-opacity-30 p-4 rounded-lg backdrop-blur-sm">
                <Link to={`/blog/${post.id}`} className="text-gray-100 hover:text-blue-300 hover:underline text-lg font-bold">
                  {post.title}
                </Link>
                <p className="text-gray-300 mt-2">{post.excerpt}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogList;
