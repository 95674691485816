import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import IdeaValidationLanding from './components/IdeaValidationLanding';
import PainpointAssessment from './components/PainpointAssessment';
import SavedAnalysis from './components/SavedAnalysis';
import Profile from './components/Profile';
import Login from './components/Login';
import Apps from './components/Apps';
import Resources from './components/resources/Resources';
import { AuthProvider } from './contexts/AuthContext';
import IndieHackerToolsDirectory from './components/resources/tools/ToolsDirectory';
import IndieHackerMediaDirectory from './components/resources/media/MediaDirectory';
import ScrollToTop from './components/ScrollToTop';
import BlogList from './components/Blogs/BlogList';
import BlogPost from './components/Blogs/BlogPost';
import PainpointAssessmentLanding from './components/PainpointAssessmentLanding';
import ContactUs from './components/ContactUs';
import ToDoPage from './components/ToDoPage';
import './App.css';

function App() {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <div className="min-h-screen w-full bg-gradient-to-r from-gray-900 via-gray-800 to-black">
          <Header />
          <Routes>
            <Route path="/" element={<IdeaValidationLanding />} />
            <Route path="/painpoint-validator" element={<PainpointAssessment />} />
            <Route path="/painpoint-validator-landing" element={<PainpointAssessmentLanding />} />
            <Route path="/saved-analysis/:id" element={<SavedAnalysis />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/login" element={<Login />} />
            <Route path="/apps" element={<Apps />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/resources/tools" element={<IndieHackerToolsDirectory />} />
            <Route path="/resources/media" element={<IndieHackerMediaDirectory />} />
            <Route path="/blogs" element={<BlogList />} />
            <Route path="/blog/:id" element={<BlogPost />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/todo" element={<ToDoPage />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
