import React, { useState } from 'react';

const SuggestMediaButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [email, setEmail] = useState('');
  const [category, setCategory] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    try {
      const response = await fetch('https://us-central1-ideahunt-329ef.cloudfunctions.net/suggestMedia', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url, email, category }),
      });

      if (response.ok) {
        setSubmitMessage('Thank you for your suggestion! We will review it soon.');
        setUrl('');
        setCategory('');
        setTimeout(() => setIsOpen(false), 3000);
      } else {
        const errorData = await response.text();
        console.error('Server responded with an error:', response.status, errorData);
        setSubmitMessage(`An error occurred (${response.status}). Please try again.`);
      }
    } catch (error) {
      console.error('Error submitting suggestion:', error);
      setSubmitMessage(`An error occurred: ${error.message}. Please try again.`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const inputClass = "mt-1 block w-full rounded-md bg-gray-800 border-gray-700 text-white focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 text-base py-2 px-3";

  return (
    <>
      <button
        className="bg-gradient-to-r from-blue-600 to-blue-800 text-white font-bold py-2 px-4 rounded-full hover:from-blue-700 hover:to-blue-900 transition-all duration-200 text-sm"
        disabled={isSubmitting}
        onClick={() => setIsOpen(true)}
      >
        Suggest Media
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-900 p-4 sm:p-6 rounded-lg w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg sm:text-xl font-bold text-gray-100">Media Suggestion</h2>
              <button
                className="text-gray-300 hover:text-gray-100"
                onClick={() => setIsOpen(false)}
              >
                ✕
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-300">
                  Email Address
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className={inputClass}
                />
              </div>
              <div>
                <label htmlFor="category" className="block text-sm font-medium text-gray-300">
                  Category
                </label>
                <select
                  id="category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                  className={inputClass}
                >
                  <option value="">Select a category</option>
                  <option value="people">People</option>
                  <option value="books">Books</option>
                  <option value="podcasts">Podcasts</option>
                </select>
              </div>
              <div>
                <label htmlFor="url" className="block text-sm font-medium text-gray-300">
                  Media URL
                </label>
                <input
                  id="url"
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  required
                  className={inputClass}
                />
              </div>
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-blue-600 to-blue-800 text-white font-bold py-2 px-4 rounded hover:from-blue-700 hover:to-blue-900 transition-all duration-200 text-sm"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
              {submitMessage && (
                <p className="text-center text-xs sm:text-sm mt-2 text-gray-300">{submitMessage}</p>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default SuggestMediaButton;
