import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SuggestMediaButton from './SuggestMediaButton';
import { useAuth } from '../../../contexts/AuthContext';
import { db } from '../../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import AddableResourceCard from '../AddableResourceCard';
import Modal from './Modal';

function Footer() {
  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-gray-800 py-2 sm:py-4">
      <div className="max-w-4xl mx-auto px-4 flex flex-row justify-between items-center">
        <p className="text-gray-400 text-xs sm:text-sm mb-2 sm:mb-0">© 2024 - <span className="bg-gradient-to-r from-cyan-400 to-blue-500 bg-clip-text text-transparent">Home</span>of<span className="bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">Founders</span></p>
        <SuggestMediaButton />
      </div>
    </footer>
  );
}

function MediaDirectory() {
  const [selectedCategory, setSelectedCategory] = useState('books');
  const [mediaItems, setMediaItems] = useState([]);

  const categories = [
    { id: 'books', name: 'Books' },
    { id: 'podcasts', name: 'Podcasts' },
    { id: 'people', name: 'People' }
  ];

  useEffect(() => {
    const fetchMediaItems = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'resources'));
        const items = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMediaItems(items);
      } catch (error) {
        console.error("Error fetching media items:", error);
      }
    };

    fetchMediaItems();
  }, []);

  return (
    <div className="min-h-screen w-full bg-gradient-to-r from-gray-900 via-gray-800 to-black p-4 sm:p-8 mb-12">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-white mb-8 text-center">Media Resources</h1>
        
        <div className="flex justify-center space-x-4 mb-8">
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => setSelectedCategory(category.id)}
              className={`px-4 py-2 rounded-full ${
                selectedCategory === category.id
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
              }`}
            >
              {category.name}
            </button>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {mediaItems
            .filter(item => item.category === selectedCategory)
            .map(item => (
              <AddableResourceCard key={item.id} resource={item} type="media" />
            ))}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default MediaDirectory;
