import React from 'react';
import { Link } from 'react-router-dom';


function Footer() {
    return (
      <footer className="bg-gray-800 py-4 sm:py-6">
        <div className="max-w-4xl mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
        <p className="text-gray-400 text-xs sm:text-sm mb-2 sm:mb-0">© 2024 - <span className="bg-gradient-to-r from-cyan-400 to-blue-500 bg-clip-text text-transparent">Home</span>of<span className="bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">Founders</span></p>
          <div className="flex space-x-4">
            <Link to="/blogs" className="text-gray-400 hover:text-white text-xs sm:text-sm">
              Blog
            </Link>
            <Link to="/contact" className="text-gray-400 hover:text-white text-xs sm:text-sm">
              Contact Us
            </Link>
          </div>
        </div>
      </footer>
    );
  }
  

const ResourceCard = ({ title, description, link }) => (
  <div 
    className="bg-gray-800 rounded-lg p-6 mb-6 hover:bg-gray-700 transition-colors border-4 border-gradient-to-r from-cyan-400 via-blue-500 to-pink-500"
    style={{
      borderImage: 'linear-gradient(to right, #22d3ee, #3b82f6, #ec4899) 1'
    }}
  >
    <div className="flex justify-between items-start mb-2">
      <h3 className="text-xl font-bold text-white">{title}</h3>
    </div>
    <p className="text-gray-300 mb-4">{description}</p>
    <Link to={link} className="bg-gray-700 text-white px-4 py-2 rounded-full hover:bg-gray-600 transition-colors inline-block">
      Explore
    </Link>
  </div>
);

function Resources() {
  const resources = [
    {
      title: "Tools Directory",
      description: "Discover a curated list of tools to build your startup.",
      link: "/resources/tools"
    },
    {
      title: "Media Directory",
      description: "Find podcasts, blogs, and other media resources for startup founders.",
      link: "/resources/media"
    }
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <div className="container mx-auto max-w-2xl px-4 py-8 flex-grow">
        <h1 className="text-3xl font-bold text-white mb-8 text-center">Startup Resources</h1>
        <div className="space-y-6">
          {resources.map((resource, index) => (
            <ResourceCard key={index} {...resource} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Resources;
      

