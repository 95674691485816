import React, { useState } from 'react';

function Contact() {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [message, setMessage] = useState('');

  const handleSend = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://us-central1-ideahunt-329ef.cloudfunctions.net/contact-us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, subject, content }),
      });

      if (response.ok) {
        setMessage('Your message has been sent!');
        setEmail('');
        setSubject('');
        setContent('');
      } else {
        setMessage('Failed to send message.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessage('Failed to send message.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-gray-900 via-gray-800 to-black">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md border-4 transition-colors"
           style={{
             borderImage: 'linear-gradient(to right, #22d3ee, #3b82f6, #ec4899) 1'
           }}>
        <h2 className="text-2xl font-bold text-white mb-6 text-center">Contact Us</h2>
        <form onSubmit={handleSend}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your Email"
            className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
            required
          />
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Subject"
            className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
            required
          />
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Your Message"
            className="w-full p-2 mb-4 bg-gray-700 text-white rounded h-32"
            required
          />
          <button
            type="submit"
            className="w-full bg-blue-600 text-white p-2 rounded hover:bg-blue-700 transition-colors"
          >
            Send
          </button>
        </form>
        {message && <p className="text-green-500 mt-4">{message}</p>}
      </div>
    </div>
  );
}

export default Contact;
