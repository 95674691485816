import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, Legend } from 'recharts';

function SavedAnalysis() {
  const [savedAnalysis, setSavedAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSavedAnalysis = async () => {
      try {
        const docRef = doc(db, 'painpointAnalyses', id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setSavedAnalysis({ id: docSnap.id, ...docSnap.data() });
        } else {
          setError('Analysis not found');
        }
      } catch (error) {
        console.error('Error fetching saved analysis:', error);
        setError('Failed to fetch saved analysis. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSavedAnalysis();
  }, [id]);

  const renderVolume = () => {
    if (!savedAnalysis || !savedAnalysis.analysis || !savedAnalysis.analysis.volume_sentiment || !savedAnalysis.analysis.trends) return null;

    const totalMentions = savedAnalysis.analysis.volume_sentiment.total_mentions;
    const mentionTrend = savedAnalysis.analysis.trends.mention_trend;

    let volumeAssessment = "";
    if (totalMentions < 100) volumeAssessment = "This is a relatively low volume of mentions, suggesting the topic might not be widely discussed.";
    else if (totalMentions < 500) volumeAssessment = "This is a moderate volume of mentions, indicating some interest in the topic.";
    else if (totalMentions < 1000) volumeAssessment = "This is a significant volume of mentions, suggesting the topic is quite popular.";
    else volumeAssessment = "This is a high volume of mentions, indicating the topic is widely discussed and very popular.";

    const currentVolumeData = [{ name: 'Total Mentions', value: totalMentions }];
    const trendData = Object.entries(mentionTrend.data).map(([date, value]) => ({ date, value }));

    let trendAssessment = "";
    if (mentionTrend.direction.trend === "Increasing") {
      trendAssessment = "The volume of mentions is increasing over time, suggesting growing interest or relevance of the topic.";
    } else if (mentionTrend.direction.trend === "Decreasing") {
      trendAssessment = "The volume of mentions is decreasing over time, which might indicate declining interest or relevance of the topic.";
    } else {
      trendAssessment = "The volume of mentions is relatively stable over time, suggesting consistent interest in the topic.";
    }

    return (
      <div className="mb-12">
        <h3 className="text-2xl font-semibold text-white mb-6">Volume Analysis</h3>
        
        <div className="bg-gray-700 p-4 rounded-lg mb-6">
          <h4 className="text-xl font-semibold text-white mb-4">Current Volume</h4>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart data={currentVolumeData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#3B82F6" />
            </BarChart>
          </ResponsiveContainer>
          <p className="text-white mt-4">Total relevant posts/comments: {totalMentions}</p>
          <p className="text-white mt-2">{volumeAssessment}</p>
        </div>

        <div className="bg-gray-700 p-4 rounded-lg">
          <h4 className="text-xl font-semibold text-white mb-4">Volume Trend</h4>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={trendData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#3B82F6" />
            </LineChart>
          </ResponsiveContainer>
          <p className="text-white mt-4">Trend: {mentionTrend.direction.trend}</p>
          <p className="text-white mt-2">{trendAssessment}</p>
        </div>
      </div>
    );
  };

  const renderSentiment = () => {
    if (!savedAnalysis || !savedAnalysis.analysis || !savedAnalysis.analysis.volume_sentiment || !savedAnalysis.analysis.trends) return null;

    const sentimentData = [
      {
        name: 'Sentiment',
        Positive: savedAnalysis.analysis.volume_sentiment.positive_percentage,
        Neutral: savedAnalysis.analysis.volume_sentiment.neutral_percentage,
        Negative: savedAnalysis.analysis.volume_sentiment.negative_percentage,
      }
    ];

    const colors = {
      Positive: '#4CAF50',
      Neutral: '#FFA726',
      Negative: '#EF5350'
    };

    const sentimentTrend = savedAnalysis.analysis.trends.sentiment_trend;
    const trendData = Object.entries(sentimentTrend.data).map(([date, value]) => ({ date, value }));

    let currentSentimentAssessment = "";
    if (sentimentData[0].Positive > sentimentData[0].Negative) {
      currentSentimentAssessment = "The overall sentiment is predominantly positive, which suggests a favorable perception of the topic.";
    } else if (sentimentData[0].Positive < sentimentData[0].Negative) {
      currentSentimentAssessment = "The overall sentiment is predominantly negative, which might indicate concerns or issues with the topic.";
    } else {
      currentSentimentAssessment = "The sentiment is balanced between positive and negative, suggesting mixed opinions on the topic.";
    }

    let trendAssessment = "";
    if (sentimentTrend.direction.trend === "Increasing") {
      trendAssessment = "The sentiment is becoming more positive over time, which could indicate improving perceptions or experiences related to the topic.";
    } else if (sentimentTrend.direction.trend === "Decreasing") {
      trendAssessment = "The sentiment is becoming more negative over time, which might suggest growing concerns or dissatisfaction with the topic.";
    } else {
      trendAssessment = "The sentiment remains relatively stable over time, indicating consistent perceptions of the topic.";
    }

    return (
      <div className="mb-12">
        <h3 className="text-2xl font-semibold text-white mb-6">Sentiment Analysis</h3>
        
        <div className="bg-gray-700 p-4 rounded-lg mb-6">
          <h4 className="text-xl font-semibold text-white mb-4">Current Sentiment</h4>
          <ResponsiveContainer width="100%" height={100}>
            <BarChart data={sentimentData} layout="vertical" stackOffset="expand">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" tickFormatter={(value) => `${(value * 100).toFixed(0)}%`} />
              <YAxis type="category" dataKey="name" hide />
              <Tooltip formatter={(value) => `${(value * 100).toFixed(2)}%`} labelFormatter={() => ''} />
              <Legend />
              {Object.keys(colors).map((key) => (
                <Bar key={key} dataKey={key} stackId="a" fill={colors[key]} />
              ))}
            </BarChart>
          </ResponsiveContainer>
          <div className="mt-4 flex justify-between text-sm">
            {Object.entries(sentimentData[0]).filter(([key]) => key !== 'name').map(([key, value]) => (
              <div key={key} className="flex items-center">
                <div className={`w-3 h-3 rounded-full mr-2`} style={{backgroundColor: colors[key]}}></div>
                <span className="text-white">{key}: {(value * 100).toFixed(2)}%</span>
              </div>
            ))}
          </div>
          <p className="text-white mt-4">{currentSentimentAssessment}</p>
        </div>

        <div className="bg-gray-700 p-4 rounded-lg">
          <h4 className="text-xl font-semibold text-white mb-4">Sentiment Trend</h4>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={trendData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#4CAF50" />
            </LineChart>
          </ResponsiveContainer>
          <p className="text-white mt-4">Trend: {sentimentTrend.direction.trend}</p>
          <p className="text-white mt-2">{trendAssessment}</p>
        </div>
      </div>
    );
  };

  const renderSubredditAnalysis = () => {
    if (!savedAnalysis || !savedAnalysis.analysis || !savedAnalysis.analysis.subreddit_analysis) return null;

    const { most_relevant_posts, highest_engagement } = savedAnalysis.analysis.subreddit_analysis;

    const relevantPostsData = Object.entries(most_relevant_posts).map(([name, value]) => ({ name, value }));
    const engagementData = Object.entries(highest_engagement).map(([name, value]) => ({ name, value: parseFloat(value.toFixed(2)) }));

    const topSubreddit = relevantPostsData.reduce((a, b) => a.value > b.value ? a : b);
    const highestEngagementSubreddit = engagementData.reduce((a, b) => a.value > b.value ? a : b);

    return (
      <div className="mb-12">
        <h3 className="text-2xl font-semibold text-white mb-6">Subreddit Analysis</h3>
        
        <div className="bg-gray-700 p-4 rounded-lg mb-6">
          <h4 className="text-xl font-semibold text-white mb-4">Most Relevant Posts by Subreddit</h4>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={relevantPostsData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
          <p className="text-white mt-4">
            The subreddit with the most relevant posts is r/{topSubreddit.name} with {topSubreddit.value} posts.
            This suggests that r/{topSubreddit.name} is a key community for discussions related to your topic.
          </p>
        </div>

        <div className="bg-gray-700 p-4 rounded-lg">
          <h4 className="text-xl font-semibold text-white mb-4">Highest Engagement by Subreddit</h4>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={engagementData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
          <p className="text-white mt-4">
            The subreddit with the highest engagement is r/{highestEngagementSubreddit.name} with an average of {highestEngagementSubreddit.value} comments per post.
            This indicates that discussions in r/{highestEngagementSubreddit.name} tend to generate more conversation and interaction.
          </p>
        </div>

        <p className="text-white mt-6">
          Consider focusing your efforts on r/{topSubreddit.name} for maximum reach and r/{highestEngagementSubreddit.name} for deeper engagement with your audience.
          These subreddits appear to be the most relevant and active communities for your topic.
        </p>
      </div>
    );
  };

  if (isLoading) return <div className="text-white">Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!savedAnalysis) return <div className="text-white">No analysis found.</div>;

  return (
    <div className="min-h-screen w-full bg-gradient-to-r from-gray-900 via-gray-800 to-black p-4">
      <div className="container mx-auto max-w-4xl">
        <div className="flex justify-between items-center mb-8">
          <Link
            to="/profile"
            className="text-gray-400 hover:text-white transition-colors"
          >
            ← Back to Profile
          </Link>
          <h1 className="text-3xl font-bold text-white text-center">Saved Analysis</h1>
          <div className="w-[120px]"></div> {/* This empty div balances the layout */}
        </div>
        
        <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">Input Data</h2>
          <p className="text-white"><strong>User Type:</strong> {savedAnalysis.userType}</p>
          <p className="text-white"><strong>Painpoint:</strong> {savedAnalysis.painpoint}</p>
        </div>

        <div className="bg-gray-800 bg-opacity-50 p-6 rounded-lg mb-8">
          <h2 className="text-2xl font-bold text-white mb-8">Analysis Results</h2>
          {renderVolume()}
          {renderSentiment()}
          {renderSubredditAnalysis()}
        </div>
      </div>
    </div>
  );
}

export default SavedAnalysis;
