import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function Header() {
  const { user } = useAuth();
  return (
    <header className="bg-gray-800 text-white p-4 border-b-4 border-gradient-to-r from-cyan-400 to-blue-500">
      <nav className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="flex flex-col md:flex-row items-center mb-4 md:mb-0">
          <Link to="/" className="text-xl font-bold mr-0 md:mr-8 mb-2 md:mb-0">
            <span className="bg-gradient-to-r from-cyan-400 to-blue-500 bg-clip-text text-transparent">Home</span>of<span className="bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">Founders</span>
          </Link>
          <Link to="/apps" className="hover:text-gray-300 mr-0 md:mr-8 mb-2 md:mb-0">Apps</Link>
          <Link to="/resources" className="hover:text-gray-300 mr-0 md:mr-8 mb-2 md:mb-0">Resources</Link>
        </div>
        <div className="flex justify-center md:justify-end items-center space-x-4">
          {user ? (
            <Link to="/profile" className="hover:text-gray-300">Profile</Link>
          ) : (
            <>
              <Link to="/login" className="hover:text-gray-300">Login</Link>
              <Link to="/login" className="bg-gradient-to-r from-cyan-400 via-blue-500 to-pink-500 text-white px-4 py-2 rounded-full hover:opacity-90 transition-colors text-sm font-semibold">Sign up for free</Link>
            </>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;