import React from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-gray-800 py-4 sm:py-6">
      <div className="max-w-4xl mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
        <p className="text-gray-400 text-xs sm:text-sm mb-2 sm:mb-0">© 2024 - HomeOfFounders</p>
        <div className="flex space-x-4">
          <Link to="/blogs" className="text-gray-400 hover:text-white text-xs sm:text-sm">
            Blog
          </Link>
          <Link to="/contact" className="text-gray-400 hover:text-white text-xs sm:text-sm">
            Contact Us
          </Link>
        </div>
      </div>
    </footer>
  );
}


const blogPosts = {
  'future-of-startups': `
# The Future of Startups: Verticalised Agents as First Hires

Every now and then I enjoy speculating on the impact of technological trends. Today's (not-so risky?) speculation is the normalisation of A.I systems, specifically verticalised agents, as tech companies first hires. 

### What are Verticalised Agents?

Verticalised agents are specialized AI systems designed to perform specific tasks within a particular industry or domain. Unlike general-purpose AI, these agents are tailored to understand the nuances and requirements of specific business verticals**.**  I will write about the techniques to build verticalised agents in another post.

### Yesterday's First Hires

Traditionally, startups begin with a founder, more often co-founders that build the foundation of the company. After seeing the product gain some traction, the founding team will hire generalist employees who wear multiple hats or specialists to support on the most urgent needs for the startup to survive and grow.

However, bringing people on board comes with its set of challenges:

- Hires not performing at the level desired.
- Dillution of founding team's equity.
- Additional risk of human conflict.

### Today's First Hires

It is impossible to ignore the fact that today's A.I systems are capable of doing the jobs of would be early stage employees without the risks and costs associated with hiring.Development assistants, marketing specialists, customer support agents and more are flooding the market and yielding impressive results.

The benefits from using verticalised agents to grow your startup include:

- Reduced hiring costs. Although these systems are not free, they are cheap compared to employee salaries.
- No risk of poor performance and overhead associated. If Github Copilot is not doing the job for you, cancel your subscription and try Cursor. You can effectively have multiple systems constantly on trial period without putting the company's morale or reputation at risk.
- No equity dillution. Jasper AI won't ask for a share of the company for being the first marketing hire.
- Scalability: Easily scale operations without the constraints of human resource management.
- 24/7 Availability: Continuous operation without breaks or time off.

### The Human Touch

An extreme conclusion from this would be that, as A.I improves, we will see many companies operate without any employee. 

Although I do believe employee bases will become slimmer, and the scale at which startups will operate with only the cofounders will increase, human talent is and will still be an important factor of differentiation.

Some companies operating in niche industries may be able to assert dominance based on the brand it managed to build using verticalised agents but in most cases, competition will increase as everyone has access to these agents and the human touch will still be of high value.

### Conclusion

Early stage startups will look like a team of co-founders manageing and using a team of verticalised agents to build, gain insights on its customer and market. As all entrepreneurs do this, speed and quality will improve leaving consumers with a fast growing choice of high quality products. Overtime, talent beyond that of the cofounders will still be needed to differentiate one company from another.

In order to help founders navigate the verticalised A.I marketplace and build their company, I am curating a directory of such agents in our Ressources section.
  `,
  'validating-an-idea': `
# Early Stage Pain-point: Validating an Idea

### The Theory Behind Validating an Idea

Before spending lots of time building a product, you want to ensure that customers want it, and most of the time, will pay for it. This is easy to understand and always mentioned in How-to’s for building software. However, what a founder needs here is details. How do you test your idea and when do you know whether you should pursue it or not.

When testing an idea, there are three dimensions that you need to optimize for and make a trade-off between: speed, cost, and certainty. You want to test your idea fast and cheaply and be sure that you have strong enough positive signals.

The key here is that you do not want to spend time on anything that is not contributing towards validating your idea before you are confident you have done so.

This is easier to do for some products than others. For this reason, what follows is focused on products for which the entirety of the value promised is in the software itself (excluding, for example, products that rely on network effects).

For a test to be valuable, it needs to be somewhat binary. The result should indicate whether to proceed or pivot.

This is how you design such a test:

- Define the size of the market you are targeting.
- Define the % of the market that needs to buy your product at a given price.
- Reach a number of people in your market that is statistically significant while balancing that with speed and cost.
- Ensure that the only thing that could deter the customer from converting is the offer.
- Evaluate the % of the reached audience that are willing to purchase your product at a given price.

If the % of the reached audience that are willing to purchase your product at a given price is higher than the % of the market that needs to buy your product at a given price, the idea has been validated. Otherwise, change the offer.

Tech is so rich,  
Antoine
  `,
  // Add more blog posts here as needed
};

const BlogPost = () => {
  const { id } = useParams();
  const content = blogPosts[id];

  if (!content) {
    return <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-700 to-gray-800 p-4 sm:p-8 text-gray-100">Blog post not found</div>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-700 to-gray-800 p-4 sm:p-8">
      <div className="max-w-3xl mx-auto bg-gray-800 bg-opacity-30 backdrop-blur-sm rounded-lg shadow-lg p-6 sm:p-8 mb-10">
        <ReactMarkdown 
          components={{
            h1: ({node, ...props}) => <h1 className="text-3xl sm:text-4xl font-bold mb-4 text-gray-100" {...props} />,
            h3: ({node, ...props}) => <h3 className="text-xl sm:text-2xl font-semibold mt-6 mb-3 text-gray-200" {...props} />,
            p: ({node, ...props}) => <p className="mb-4 text-gray-300" {...props} />,
            ul: ({node, ...props}) => <ul className="list-disc list-inside mb-4 text-gray-300" {...props} />,
            li: ({node, ...props}) => <li className="mb-2" {...props} />,
            a: ({node, ...props}) => <a className="text-blue-300 hover:underline" {...props} />,
          }}
        >
          {content}
        </ReactMarkdown>
      </div>
      <Footer />
    </div>
  );
};

export default BlogPost;
