import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase'; // Ensure this is your Firebase auth instance
import Header from './Header';
import { Link } from 'react-router-dom';

function PainpointAssessmentLanding() {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, loading, error] = useAuthState(auth); // Use the useAuthState hook

  const handleCTAClick = () => {
    if (user) {
      console.log('User is logged in, navigating to painpoint-validator');
      navigate('/painpoint-validator');
    } else {
      console.log('User is not logged in, navigating to login');
      navigate('/login', { state: { from: location } });
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Optionally handle loading state
  }

  if (error) {
    console.error('Error checking auth state:', error.message);
    return <div>Error loading authentication state</div>; // Optionally handle error state
  }

  return (
    <div className="min-h-screen w-full bg-gradient-to-r from-gray-900 via-gray-800 to-black flex flex-col justify-between">
      <div className="flex-grow flex items-center">
        <div className="max-w-3xl mx-auto px-4 py-16 text-center mt-16 ">
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-10 font-sans tracking-tight leading-tight">
            Validate painpoints and <span className="bg-gradient-to-r from-cyan-400 to-blue-500 text-transparent bg-clip-text">solve real problems</span> for your <br /> customers
          </h1>
          <h2 className="text-xl md:text-3xl font-bold text-white mb-20">
           Use Social Media signals to validate your customer problems in minutes
          </h2>
          <div className="flex flex-col md:flex-row justify-center gap-8 mb-32">
            <ContentBubble title="Social Media trends" className="md:w-1/3">
            </ContentBubble>
            <ContentBubble title="Sentiment Analysis" className="md:w-1/3">
            </ContentBubble>
            <ContentBubble title="Find your customers" className="md:w-1/3">
            </ContentBubble>
          </div>
          <div className="flex justify-center">
            <button
              onClick={handleCTAClick}
              className="bg-gradient-to-r from-blue-600 to-blue-800 text-white px-8 py-3 rounded-full hover:bg-gray-600 transition-colors text-lg font-semibold"
            >
              Validate a Painpoint
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const ContentBubble = ({ title, children, className = "" }) => {
  return (
    <div className={`bg-gray-800 rounded-xl p-6 text-white ${className}`}>
      <h2 className="text-xl font-semibold mb-2">{title}</h2>
      <p className="text-sm text-gray-300">{children}</p>
    </div>
  );
};

function Footer() {
    return (
      <footer className="bg-gray-800 py-4 sm:py-6">
        <div className="max-w-4xl mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
          <p className="text-gray-400 text-xs sm:text-sm mb-2 sm:mb-0">© 2024 - Painpoint Validator by <span className="bg-gradient-to-r from-cyan-400 to-blue-500 bg-clip-text text-transparent">Home</span>of<span className="bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">Founders</span></p>
          <div className="flex space-x-4">
            <Link to="/blogs" className="text-gray-400 hover:text-white text-xs sm:text-sm">
              Blog
            </Link>
            <Link to="/contact" className="text-gray-400 hover:text-white text-xs sm:text-sm">
              Contact Us
            </Link>
          </div>
        </div>
      </footer>
    );
  }

export default PainpointAssessmentLanding;
