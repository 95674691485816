import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import SuggestToolButton from './SuggestToolButton';



function Footer() {
  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-gray-800 py-2 sm:py-4">
      <div className="max-w-4xl mx-auto px-4 flex flex-row justify-between items-center">
        <p className="text-gray-400 text-xs sm:text-sm mb-2 sm:mb-0">© 2024 - <span className="bg-gradient-to-r from-cyan-400 to-blue-500 bg-clip-text text-transparent">Home</span>of<span className="bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">Founders</span></p>
        <SuggestToolButton />
      </div>
    </footer>
  );
}
const IndieHackerToolsDirectory = () => {
  const [tools, setTools] = useState([]);
  const [activeSection, setActiveSection] = useState('tools');
  const [activeCategory, setActiveCategory] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTools = async () => {
      try {
        const q = query(collection(db, 'resources'), where('type', '==', 'tool'));
        const querySnapshot = await getDocs(q);
        const fetchedTools = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setTools(fetchedTools);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching tools:", error);
        setLoading(false);
      }
    };

    fetchTools();
  }, []);

  const toolStages = [
    'Ideate & Research 💡',
    'Plan & Design 🎨',
    'Build & Develop 🛠️',
    'Launch & Grow 🚀',
    'Operate & Scale 📈'
  ];

  const aiCategories = [
    'AI for Design',
    'AI for Development',
    'AI for Marketing',
    'AI for Hiring',
    'AI for Legal'
  ];

  useEffect(() => {
    setActiveCategory(activeSection === 'tools' ? toolStages[0] : aiCategories[0]);
  }, [activeSection]);

  const filteredTools = tools.filter(tool => 
    activeSection === 'tools' 
      ? tool.stage === activeCategory
      : tool.category === activeCategory.replace('AI for ', '')
  );

  if (loading) {
    return <div className="text-white">Loading tools...</div>;
  }

  return (
    <div className="min-h-screen w-full bg-gradient-to-r from-gray-900 via-gray-800 to-black p-4 sm:p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-3xl font-bold text-white mb-8 text-center">All the tools you need to build your startup</h1>
        
        <div className="flex justify-center space-x-4 mb-8">
          <button
            onClick={() => setActiveSection('tools')}
            className={`px-4 py-2 rounded-full ${activeSection === 'tools' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'}`}
          >
            Tools by Stage
          </button>
          <button
            onClick={() => setActiveSection('ai')}
            className={`px-4 py-2 rounded-full ${activeSection === 'ai' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'}`}
          >
            Verticalized AI
          </button>
        </div>
        
        <div className="flex flex-col md:flex-row">
          {/* Sidebar */}
          <div className="w-full md:w-1/4 mb-4 md:mb-0 md:pr-4">
            <h2 className="text-xl font-bold text-white mb-4">
              {/* {activeSection === 'tools' ? 'Stages' : 'AI Categories'} */}
            </h2>
            <div className="space-y-2">
              {(activeSection === 'tools' ? toolStages : aiCategories).map(category => (
                <button
                  key={category}
                  onClick={() => setActiveCategory(category)}
                  className={`w-full text-left px-4 py-2 rounded ${
                    activeCategory === category
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>

          {/* Main content */}
          <div className="w-full md:w-3/4 md:pl-4">
            {/* <h2 className="text-2xl font-bold text-white mb-4">{activeCategory}</h2> */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredTools.map(tool => (
                <div key={tool.id} className="bg-gray-800 rounded-lg p-4 flex flex-col h-full">
                  <h3 className="text-lg font-semibold text-white mb-2">{tool.name}</h3>
                  <p className="text-gray-300 mb-2">Category: {tool.category}</p>
                  <p className="text-gray-300 mb-4 flex-grow">{tool.description}</p>
                  <a 
                    href={tool.link} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-blue-400 hover:text-blue-300"
                  >
                    Learn More
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IndieHackerToolsDirectory;
