import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { Link } from 'react-router-dom';



function Footer() {
  return (
    <footer className="bg-gray-800 py-4 sm:py-6">
      <div className="max-w-4xl mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
         <p className="text-gray-400 text-xs sm:text-sm mb-2 sm:mb-0">© 2024 - <span className="bg-gradient-to-r from-cyan-400 to-blue-500 bg-clip-text text-transparent">Home</span>of<span className="bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">Founders</span></p>
      <div className="flex space-x-4">
          <Link to="/blogs" className="text-gray-400 hover:text-white text-xs sm:text-sm">
            Blog
          </Link>
          <Link to="/contact" className="text-gray-400 hover:text-white text-xs sm:text-sm">
            Contact Us
          </Link>
        </div>
      </div>
    </footer>
  );
}

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      if (isSignUp) {
        await createUserWithEmailAndPassword(auth, email, password);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      navigate(from);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-grow flex justify-center items-center">
        <div
          className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md border-4 transition-colors"
          style={{
            borderImage: 'linear-gradient(to right, #22d3ee, #3b82f6, #ec4899) 1'
          }}
        >
          <h2 className="text-2xl font-bold text-white mb-6 text-center">{isSignUp ? 'Sign Up For Free' : 'Login'}</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className="w-full p-2 mb-4 bg-gray-700 text-white rounded"
              required
            />
            <button
              type="submit"
              className="w-full bg-blue-600 text-white p-2 rounded hover:bg-blue-700 transition-colors"
            >
              {isSignUp ? 'Sign Up' : 'Login'}
            </button>
          </form>
          <button
            onClick={() => setIsSignUp(!isSignUp)}
            className="w-full mt-4 text-blue-400 hover:text-blue-300"
          >
            {isSignUp ? 'Already have an account? Login' : "Don't have an account? Sign up for free (no credit card required)"}
          </button>
          {error && <p className="text-red-500 mt-4">{error}</p>}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
